import React from "react";
import tg from "../../../../assets/Images/tg.png";
import Twitter from "../../../../assets/Images/Twitter.png";
import { DesktopMainMenu,  Imagedata,  MenuLink } from "../styles";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { StyledBox } from "../../../Styles";
 const Div = styled.div`

 `
const DesktopMenu = (props) => {
 
  return (
    <DesktopMainMenu maxWidth="xl">
      <Div style={{display:"flex" , alignItems:"center", justifyContent: 'right'}}>
        {props.menuList.map((value, i) => <MenuLink  p={value.p} key={i} href={value.link} target={value.target} className={value.customClass}>{value.title}</MenuLink> )}
      </Div>
      <Box display="flex" gap="20px">
        <StyledBox scale="scale(1.1)" href="https://t.me/scratSOLANA" target="blank">
          <Imagedata src={tg} mw="20px" alt="Logo" />
        </StyledBox>
        <StyledBox scale="scale(1.1)"  href="https://twitter.com/scratSOLANA" target="blank">
          <Imagedata src={Twitter} mw="20px" alt="Logo" />
        </StyledBox>
      </Box>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
