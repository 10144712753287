import React, { useEffect, useState } from 'react';
import Home, { HeaderDesign, StyledImage, StyledVideo } from '../../Components/Sections/S1-Home';
import Tokenomics from '../../Components/Sections/S3-Tokenomics';
import Summary from '../../Components/Sections/S4-Footer';
import AboutSection from '../../Components/Sections/S2-About';
import { Box, Container, Grid } from '@mui/material';
import Paragraph from '../../Components/Paragraph';
import { StyledBox } from '../../Styles';

import snow from "../../../assets/Images/snow.mp4";
import heroImage from "../../../assets/Images/heroImage.png";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..


 
const Main = () => {
 
  const [pageState , setPageState] = useState(true)
 useEffect(()=>{
  AOS.init({
    duration:800,

  });
 })
 return (<>
    
  <StyledVideo src={snow} controls={false}  autoPlay loop muted />
      {
        pageState ? 
        <>
        <Box sx={{background: "linear-gradient(to bottom, #0076FF,#70B2FF)",minHeight:"100dvh",width:"100%",overflow:"hidden"}}>
          <HeaderDesign id="home">
          <StyledImage src={heroImage} mw="200px !important" alt='scrat' />
             
            <Container maxWidth="xl"  sx={{ minHeight:"calc(100dvh)" ,display:"flex", flexDirection:"column", justifyContent:"end", paddingBottom:"30px",position:"relative"}}>
              <Grid container>
                <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" justifyContent="end" gap="0px" zIndex="9999">
                  <Paragraph data-aos="zoom-in" textAlign="center" color="#fff" fontFamily="Goonies" sx={{letterSpacing:{xs:20,sm:10}}} fontSize="clamp(1.875rem, 0.893vw + 1.696rem, 2.5rem)">
                    I'm looking for
                  </Paragraph>
                  <Paragraph data-aos="zoom-in" textAlign="center" color="#fff" fontFamily="Goonies" fontSize="clamp(5rem, 5vw + 4rem, 10rem)" sx={{letterSpacing:{xs:20,sm:30}}}>
                    Nuts
                  </Paragraph>
                  <StyledBox onClick={()=>{setPageState(false)}} sx={{backgroundColor:"#2689FB",border:'1px solid white !important',cursor:"pointer", borderBottom:'7px solid white !important', maxWidth:'300px !important',width:'100%', textDecoration:'none'}}>
                    <Paragraph textAlign="center" fontFamily="ManjariB" fontSize="clamp(1rem, 0.268vw + 0.946rem, 1.188rem)" my="0px" >
                      Continue
                    </Paragraph>
                  </StyledBox>
                </Grid>
                  
              </Grid>
        </Container>
        </HeaderDesign> 
      </Box>
        </>:
        <>   
            <Home /> 
            <AboutSection/>
            <Tokenomics /> 
            <Summary />
        </>
}</>
    );
}
export default Main;