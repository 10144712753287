import  { Box, Container, Grid} from '@mui/material';
import MainBg from "../../../../assets/Images/heroBg.png";
import heroImage from "../../../../assets/Images/heroImage.png";
import bottomImage from "../../../../assets/Images/bottomImage.png";
import raydium from "../../../../assets/Images/raydium.png";
import juputer from "../../../../assets/Images/juputer.png";
import solscan from "../../../../assets/Images/solscan.png";
import dextools from "../../../../assets/Images/dextools-1.png";
import dexscreener from "../../../../assets/Images/dexscreener-1.png";
import styled from '@emotion/styled';
import Paragraph from '../../Paragraph';
import LockerMenu from '../../LockerMenu';
import { StyledBox } from '../../../Styles';
import { Imagedata } from '../../LockerMenu/styles';
import { useState } from 'react';


export const HeaderDesign = styled.div`
  min-height: 100dvh;
  position: relative;
  z-index: 2;
  /* scroll-snap-align:start; */
  background-image: url(${MainBg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  /* padding-bottom:100px; */
  /* border-image: fill 0 linear-gradient(transparent 60%,#000 95%); */
  @media(max-width: 900px){
    min-height: 100vh;
  }
`

export const StyledHeading = styled(Paragraph)`
  position:absolute;
  top:50%;
  left:50%;
  z-index: 0;
  transform: translate(-50%,-50%);
 
  
`
export const StyledHeadingStrok = styled(Paragraph)`
  
  position:absolute;
  top:50%;
  left:50%;
  z-index: 99;
  transform: translate(-50%,-50%);
    color: transparent;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: #fff;
   
`
export const StyledImage = styled.img`
  position:absolute;
  top:0%;
  left:50%;
  z-index: 10;
  width: 100%;
  max-width: ${props => props.mw ? props.mw:"280px"};
  transform: translate(-50%,0%);
  animation: .8s ease moveDown;
  @media(max-width: 599px){
    max-width: 280px;
  }
  
`
export const StyledBottomImage = styled.img`
  position:absolute;
  top:90%;
  left:0px;
  width: 100%;
  @media(max-width: 599px){
    top:94%;
  }
  
`
export const StyledVideo= styled.video`
  position:fixed;
  top:0%;
  left:0px;
  width: 100%;
  height: 100dvh;
  z-index:1;
  object-fit: cover;
  mix-blend-mode: plus-lighter;
  
`


const Home = () => {
  const [isCopied, setIsCopied] = useState(false)
  const [walletAddress, setwalletAddress] = useState("CONTRACT ADDRESS")
  
  const handleCopy = ()=>{
    navigator.clipboard.writeText(walletAddress)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000);
  }
  return (<>
   
      <Box sx={{background: "linear-gradient(to bottom, #0076FF,#70B2FF)",position:"relative",overflow:"hidden"}}>
      
      <HeaderDesign id="home">
      <LockerMenu /> 
        <StyledImage src={heroImage} alt='scrat' />
            <StyledHeading color="#fff" fontFamily="Goonies" fontSize="clamp(3.125rem, 15.695vw - 0.014rem, 25rem)" sx={{letterSpacing:{xs:20,sm:70}}}>SCRAT</StyledHeading>
            <StyledHeadingStrok color="#fff" fontFamily="Goonies" fontSize="clamp(3.125rem, 15.695vw - 0.014rem, 25rem)" sx={{letterSpacing:{xs:20,sm:70}}}>SCRAT</StyledHeadingStrok>
      <Container maxWidth="xl"  sx={{ minHeight:"calc(100vh - 84px)" ,display:"flex", flexDirection:"column", justifyContent:"center", paddingTop:{xs:"15%",md:"2%"}, paddingBottom:{xs:"15%",md:"2%"},position:"relative"}}>
            <Grid container>
              <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" justifyContent="end" gap="10px" minHeight="67vh">
                <Paragraph data-aos="zoom-in" fontFamily="ManjariB" sx={{letterSpacing:{xs:2,sm:10}}} fontSize="clamp(1.375rem, 0.448vw + 1.285rem, 2rem)">
                  HI, I’M $SCRAT!
                </Paragraph>
               
                <StyledBox  onClick={handleCopy} sx={{backgroundColor:"#2689FB",border:'1px solid white !important', borderBottom:'7px solid white !important', maxWidth:'600px !important',width:'100%', textDecoration:'none'}}>
                  <Paragraph fontFamily="ManjariB" fontSize="clamp(1rem, 0.268vw + 0.946rem, 1.188rem)" sx={{paddingTop:"6px"}}  my="0px" >
                  {isCopied ? "Copied ✔" : walletAddress}
                  </Paragraph>
                </StyledBox>
              </Grid>
                
            </Grid>
        <StyledBottomImage src={bottomImage} alt='scrat' /> 
      </Container>
      </HeaderDesign> 
      <Box sx={{padding:"40px 10px 50px", minHeight:"360px",display:"flex",alignItems:"end",background:"#3693FF"}}  > 
          <Grid container>
            <Grid item xs={12} display="flex" justifyContent="center" paddingBottom="20px">
                <Paragraph Paragraph fontFamily="ManjariB" sx={{letterSpacing:{xs:2,sm:10}}} fontSize="clamp(1.375rem, 0.448vw + 1.285rem, 2rem)">
                  AVAILABLE ON
                </Paragraph>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center" gap="40px 70px" flexWrap="wrap">
              <Imagedata data-aos="fade-up" data-aos-duration="600" mw="125px" src={raydium} />
              <Imagedata data-aos="fade-up" data-aos-duration="800" mw="90px" src={juputer} />
              <Imagedata data-aos="fade-up" data-aos-duration="1000" mw="140px" src={solscan} />
              <Imagedata data-aos="fade-up" data-aos-duration="1200" mw="120px" src={dextools} />
              <Imagedata data-aos="fade-up" data-aos-duration="1400" mw="120px" src={dexscreener} />
            </Grid>
            </Grid>
            </Box>
            </Box>
          </>
  );
}
 
export default Home;