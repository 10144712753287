import React from 'react';
import styled from '@emotion/styled';
import { Container, Grid } from '@mui/material';
import aboutBg from "../../../../assets/Images/aboutBg.png";
import Paragraph from '../../Paragraph';
import aboutImage from "../../../../assets/Images/aboutImage.png";
import { Imagedata } from '../../LockerMenu/styles';
const Blackbg = styled(Container)`
    background-image: url(${aboutBg});
    background-position: center;
    background-size: cover;
    position:relative;
    z-index: 0;
    min-height:  ${(props) => props.noht ? "auto" : "900px"} ;
    display: flex;
    align-items: start;
    padding-top:180px;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 20px;
    overflow: hidden;
    /* scroll-snap-align:start; */
    @media(max-width: 899px){
        padding-top:0px;
        min-height: auto ;
        background-image: none;
    }
    `
const AboutSection = () => {
    return (
        <Blackbg maxWidth="xl" id="about" >
            {/* <Motionimg initial={{x:"-100%"}} whileInView={{x:"0%"}} viewport={{once: true}} transition={{duration: 1}} src={Purplebg} alt="purple pic" /> */}
            <Grid container display="flex" justifyContent="end"  alignItems="start" >
                <Grid item xs={12} sm={6} sx={{display:{xs:"block",md:"none"}}}>
                    <Imagedata mw="100%" src={aboutImage} />
                </Grid>
                <Grid item xs={12} sm={6} display="flex" flexDirection="column" justifyContent="start" gap={{sx:'0xp', lg : '10px'}} alignItems="start" padding="20px">
                    <Paragraph data-aos="fade-up" sx={{textTransform:"uppercase" }} fontFamily="ManjariB" padding="30px 0" fontWeight="300" fontSize="clamp(1.625rem, 1.5vw + 1.325rem, 3.125rem)" color="#fff" > 
                        About <br />
                        $SCRAT
                    </Paragraph> 
                    <Paragraph  data-aos="fade-left" fontFamily="ManjariB" fontWeight="600" fontSize="16px" sx={{color:{xs:"#fff",md:"#000"}}}> 
                        Hi, I'm Scrat, the determined squirrel on a quest for my acorn! Our token embodies resilience and adventure, echoing my pursuit against all odds. Join our community for a thrilling journey filled with excitement!
                    </Paragraph> 
                </Grid>
            </Grid>
        </Blackbg>
           
    );
}
 
export default AboutSection;