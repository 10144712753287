import { Box, Grid } from '@mui/material';
import React from 'react';
import { Img } from '../../Styles/Maintext.styles';
import Paragraph from '../Paragraph';

const   Cardimg = (props) => {
    return (
        <Grid item  sm={4.5}  md={2.3} xs={12}  display="flex"  padding="15px 25px " gap="15px" flexDirection="column" position="relative" borderRadius="10px" bgcolor={props.bg? props.bg: "white"} minHeight="auto"  {...props}>
            <Img  src={props.psrc} alt="pic" paddingLeft="" />
            <Box display="flex"  flexDirection="column" justifyContent="start" padding={props.padd ? props.padd:""}>
            <Paragraph color="#000000" fontWeight="600" fontFamily="ManjariB" fontSize="18px" >{props.def1}</Paragraph>
            <Paragraph  color="#000000" fontFamily="ManjariB" my="0px" fontWeight="600" fontSize="25px">{props.def2}</Paragraph>   
            </Box>
 
        </Grid>
      );
}
 
export default Cardimg; 